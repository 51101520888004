.form-control {
  outline: none;
  box-shadow: none !important;
}

input {
  border-radius: 8px !important;
  font-weight: 500 !important;
  color: #171717 !important;
  font-size: 20px !important;
  padding: 12px !important;
}

input:active,
input:focus {
  border: 2px solid #d97706 !important;
}

button {
  width: 100%;
  border: none;
  background: #d97706;
  border-radius: 24px;
  padding: 14px;
  color: #ffffff;
  transition: all 270ms ease-in-out;
}

button:hover {
  filter: brightness(0.9);
}

button:disabled {
  background: #e4e4e5;
  color: #242424;
}

.social-btn {
  background-color: #e4e4e5;
  color: #242424;
  font-weight: 500;
  border-radius: 28px;
}

.back {
  background-color: #f3f0f0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 15px;
  color: #d97706;
}