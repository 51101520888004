* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Manrope", sans-serif !important;
  background-color: #fdf8f4 !important;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: #fafafa !important;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  opacity: 0.7;
}

.skeleton-search {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.skeleton-head {
  height: 25px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.skeleton-p {
  height: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.skeleton-img {
  max-width: 260px;
  height: 265px;
}

.skel {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  background-color: hsl(200, 13%, 91%);
}

.skeleton-circle {
  position: absolute;
  top: 50px;
  left: 50px;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(199, 18%, 83%);
  }
  100% {
    background-color: hsl(200, 11%, 95%);
  }
}

.form-control {
  background-color: #fafafa !important;
  border-radius: 6px !important;
  border: none !important;
  padding: 12px !important;
  color: #171717 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

input:focus,
textarea:focus,
select:focus {
  border: 1px solid #d97706 !important;
}

label {
  font-size: 14px;
  font-weight: 500;
  color: #404040;
  line-height: 20px;
}

.login-btn {
  padding: 14px;
  width: 100%;
  border-radius: 24px !important;
  background-color: #d97706;
  gap: 10px;
  border: none;
  color: #ffffff;
}

.card {
  border: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.text-orange {
  color: #d97706;
}

.text-green {
  color: #059669;
}

.submit-btn {
  padding: 8px 25px;
  background-color: #059669;
  color: #ffffff;
  border-radius: 10px;
  border: none;
}

button:disabled,
button[disabled]{
  border: none;
  background-color: #6ee7c1e3;
  color: #666666;
}

.buy-btn {
  padding: 8px 25px;
  background-color: #d97706;
  color: #ffffff;
  border-radius: 10px;
  border: none;
}

.sort {
  cursor: pointer;
}

.map {
  position: relative;
}

.search {
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
}

.wallet-card {
  background-color: #ebfff8;
  border: 1px solid #059669;
  border-radius: 5px;
  padding: 30px 15px;
}

.trans {
  background-color: rgba(255, 101, 1, 0.12);
  padding: 20px 30px;
  margin: 20px 15px;
  border-radius: 5px;
}

.first {
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  font-size: 20px;
}

td {
  height: 70px !important;
}

.qty-btn {
  border: none;
  padding: 8px 14px;
  margin: 10px 0;
  color: #ffffff;
  background-color: #d97706;
  border-radius: 5px;
}

.cart {
  color: #ffffff;
}

.cart:hover {
  color: #d97706;
  cursor: pointer;
}

.cart-number {
  background-color: #d97706;
  color: #ffffff;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 1px 5px;
  border-radius: 50%;
}

@media screen and (max-width: 630px) {
  .main-card {
    max-width: 320px !important;
    transform: translateX(50px);
  }
  .reverse {
    display: flex;
    flex-direction: column-reverse;
  }
}

label {
  color: #059669;
}